<template>
    <div v-loading="loading">
        <!--项目信息-->
        <div class="f1">
            <!--报名中 - 无邀请信息-->
            <div class="left" v-if="task.status == 3 && task.trade == ''">
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">报名中</span>
                    <span class="jd_sy">剩余</span>
                    <span class="jd_time">
                        <counter :endTime="task.end_time" @endCount="enrollEndEvent(task.tk)"></counter>
                    </span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img1.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">项目已成功发布</p>
                            <p class="desc">您的项目已经成功发布，请随时关注项目报名情况。</p>
                            <p class="btns">
                                <el-button
                                    v-if="task.enroll && task.enroll.length > 0"
                                    type="mini"
                                    class="btn"
                                    @click="isShowDraw = true"
                                     style="width:124px;"
                                >查看报名</el-button>
                                <el-button v-else type="mini" class="btn" disabled style="width:124px;">暂无极客报名</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--报名已结束 - 进入评选阶段 - 无邀请信息-->
            <div class="left" v-if="task.status == 4 && task.trade == ''">
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">报名已结束</span>
                    <span class="jd_sy">剩余</span>
                    <span class="jd_time">
                        <counter :endTime="task.end_time" @endCount="enrollEndEvent(task.tk)"></counter>
                    </span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img4.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">项目已进入评选期</p>
                            <p
                                class="desc"
                            >这个项目的报名时间已经结束，您可以根据报名情况选择合作的工程极客。报名截止的7天后，如未选中项目工程极客，项目将自动终止。</p>
                            <p class="btns">
                                <el-button
                                    v-if="task.enroll && task.enroll.length > 0"
                                    type="mini"
                                    class="btn"
                                    @click="isShowDraw = true"
                                     style="width:124px;"
                                >查看报名</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--评选中-->
            <div class="left" v-if="task.status == 4 && task.trade">
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">报名已结束</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img4.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">项目已进入评选期</p>
                            <p
                                class="desc"
                            >这个项目的报名时间已经结束，您可以根据报名情况选择合作的工程极客。报名截止的7天后，如未选中项目工程极客，项目将自动终止。</p>
                            <p class="btns">
                                <el-button
                                    v-if="task.enroll && task.enroll.length > 0"
                                    type="mini"
                                    class="btn"
                                    @click="isShowDraw = true"
                                     style="width:124px;"
                                >查看报名</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--邀请中-->
            <div class="left" v-if="task.status == 3 && task.trade && task.trade.status==0">
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">邀请中</span>
                    <span class="jd_sy">剩余</span>
                    <span class="jd_time">
                        <counter
                            :endTime="task.invited_end_time"
                            @endCount="enrollEndEvent(task.tk)"
                        ></counter>
                    </span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img3.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">您已向工程极客发出邀请！</p>
                            <p class="desc">邀请已发出，请耐心等待，如工程极客在您发出邀请后的12小时内未接受项目，则您可以重新选择工程极客。</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    class="btn"
                                    @click="showInvite(task.trade.trade_sn)"
                                     style="width:124px;"
                                >查看邀请</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--极客邀请 - 确认邀请超时-->
            <div class="left" v-if="task.status == 3 && task.trade && task.trade.status==-2">
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">极客未回应</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img3.png'" />
                    </div>
                    <div class="content">
                        <p class="title">工程极客没有回应</p>
                        <p class="desc">您所邀请的工程极客在限定时间内没有回应，您可以重新向已报名的工程极客发起邀请。</p>
                        <p class="btns">
                            <el-button type="mini" class="btn" @click="isShowDraw = true" style="width:124px;">查看报名</el-button>
                        </p>
                    </div>
                </div>
            </div>

            <!--拒绝邀请-->
            <div class="left" v-if="task.status == 3 && task.trade  && task.trade.status==-1">
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">邀请被拒绝了</span>
                    <span class="jd_sy"></span>
                    <span class="jd_time"></span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img
                            v-if="task.status == 3"
                            :src="websiteConfigs.sourceUrl+'/upload/image/todo_img0.png'"
                        />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">工程极客拒绝了您的邀请</p>
                            <p class="desc">您发出的邀请被拒绝了，您可以重新向已报名的工程极客发起邀请。</p>
                            <p class="btns">
                                <el-button
                                    v-if="task.enroll && task.enroll.length > 0"
                                    type="mini"
                                    class="btn"
                                    @click="isShowDraw = true"
                                     style="width:124px;"
                                >查看报名</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--托管酬金-->
            <div
                class="left"
                v-if="task.status == 6 && task.trade &&  task.trade.is_remuneration==1"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">请托管酬金</span>
                    <span class="jd_sy">剩余</span>
                    <span class="jd_time">
                        <counter
                            :endTime="task.remuneration_end_time"
                            @endCount="enrollEndEvent(task.tk)"
                        ></counter>
                    </span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img10.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">工程极客已经接受了您的邀请，请在倒计时内完成托管！</p>
                            <p class="desc">双方成功托管资金后，工程极客即可开始为您工作。</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    class="btn"
                                    v-if="task.trade && task.trade.remuneration_time==0"
                                    @click="payTypeSelectDialog = true"
                                     style="width:124px;"
                                >托管酬金</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--工作中-->
            <div class="left" v-if="task.status == 7">
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目进行中</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img6.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">工程极客正在执行您的项目</p>
                            <p class="desc">
                                您和工程极客已将资金成功托管，你所设置的项目成果交付日期为：
                                {{task.trade.delivery_time}}，您可以随时关注项目的进展状态。
                            </p>
                            <p class="btns">
                                <a
                                    href="#progress_box_position"
                                    class="btn task_progress_view"
                                    style="width:124px;"
                                >查看进度</a>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--已完成 - 未评价-->
            <div
                class="left"
                v-if="task.status == 8 && (task.appraise=='' || (task.appraise && task.appraise.employer_appraise_time==0))"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目已完成</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img8.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">酬金已支付</p>
                            <p class="desc">您已经成功支付酬金，快去评价一下合作的工程极客吧~</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    @click="task_appraise(task.tk)"
                                    class="btn"
                                     style="width:124px;"
                                >立即评价</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--已完成 - 已评价-->
            <div
                class="left"
                v-if="task.status == 8 && task.appraise && task.appraise.employer_appraise_time>0"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目已完成</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img8.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">已评价</p>
                            <p class="desc">该项目已成功完成，您已提交评价。</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    @click="view_appraise(task.tk)"
                                    class="btn"
                                     style="width:124px;"
                                >查看评价</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--项目结束 - 线下交易-->
            <div
                class="left"
                v-if="task.status==10 && task.trade && task.trade.is_remuneration !=1 && task.log_current && (task.log_current.code < 31 || task.log_current.code > 37)"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目已结束</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img5.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">工程极客已经接受了您的邀请！</p>
                            <p
                                class="desc"
                            >工程极客已经接受了您线下执行项目的邀请，您可以自行与工程极客进行联系并线下完成交易，在线下交易中，请注意防范风险，避免损失。</p>
                            <p class="btns"></p>
                        </template>
                    </div>
                </div>
            </div>

            <!--项目结束 - 报名时间到 - 没有极客报名-->
            <div
                class="left"
                v-if="task.status == 10 && task.enroll=='' && task.log_current && (task.log_current.code < 31 || task.log_current.code > 37)"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目已结束</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img5.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">没有人报名</p>
                            <p class="desc">项目报名已截止，没有任何工程极客报名这个项目，此项目已自动结束。</p>
                            <p class="btns"></p>
                        </template>
                    </div>
                </div>
            </div>

            <!--项目结束 - 极客报名7天内雇主未发起邀请-->
            <div
                class="left"
                v-if="task.status == 10 && task.enroll && (task.trade=='' || task.trade.status == -1 || task.trade.status == -2) && (!task.log_current || task.log_current.code == 7)"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目已结束</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img5.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">您未发出邀请</p>
                            <p class="desc">项目报名截止7天内，您未发出邀请，项目自动结束。</p>
                            <p class="btns"></p>
                        </template>
                    </div>
                </div>
            </div>

            <!--项目结束 - 雇主托管酬金超时-->
            <div class="left" v-if="task.status == 10 && task.trade && task.trade.status==-5">
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目已结束</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img5.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">托管酬金超时</p>
                            <p class="desc">您未在规定时间内托管项目酬金，项目自动结束。</p>
                            <p class="btns"></p>
                        </template>
                    </div>
                </div>
            </div>

            <!--项目结束 - 极客托管履约保证金超时-->
            <div class="left" v-if="task.status == 10 && task.trade && task.trade.status==-4">
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目已结束</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img5.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">托管履约保证金超时</p>
                            <p class="desc">工程极客未在规定时间内托管履约保证金，项目自动结束。</p>
                            <p class="btns"></p>
                        </template>
                    </div>
                </div>
            </div>

            <!--项目结束 - 雇主手动结束-->
            <div
                class="left"
                v-if="task.status == 10 && task.finish_time>0 && task.finish_admin==0 && task.log_current && task.log_current.code > 30 && task.log_current.code < 37"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目结束</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img5.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">项目已结束</p>
                            <p class="desc">您已经主动关闭了这个项目。您可以点击以下按钮查看关闭原因。</p>
                            <p class="btns"></p>
                        </template>
                    </div>
                </div>
            </div>

            <!--项目结束 - 平台强行结束项目-->
            <div class="left" v-if="task.status == 10 && task.finish_time>0 && task.finish_admin>0">
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目结束</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img5.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">项目已结束</p>
                            <p class="desc">该项目被平台强制结束。您可以点击以下按钮查看关闭原因。</p>
                            <p class="btns"></p>
                        </template>
                    </div>
                </div>
            </div>

            <!--调解中 - 3天内-->
            <template v-if="task.status == 9  && task.appeal_deal && task.appeal_deal.status==0">
                <template
                    v-if="task.appeal && task.appeal.length>0 && task.appeal[0].uk==userInfo.info.uk"
                >
                    <div class="left" v-if="task.status == 9 ">
                        <div class="top">
                            <span class="jd_name">当前进度：</span>
                            <span class="jd_status">调解中</span>
                            <span class="jd_sy">剩余</span>
                            <span class="jd_time">
                                <counter
                                    :endTime="task.appeal_end_time"
                                    @endCount="enrollEndEvent(task.tk)"
                                ></counter>
                            </span>
                        </div>

                        <div class="bottom">
                            <div class="icon">
                                <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img7.png'" />
                            </div>
                            <div class="content">
                                <template>
                                    <p class="title">您已发起了调解申请</p>
                                    <p
                                        class="desc"
                                    >您已发起了调解申请，双方可以在3天内提交意见与证据，询龙网会根据双方所提交的意见与证据提出调解意见。</p>
                                    <p class="btns">
                                        <el-button
                                            type="mini"
                                            @click="appealDetail(task.tk,(task.appeal_deal.status==0)?0:1)"
                                            class="btn"
                                             style="width:124px;"
                                        >提交证明及资料</el-button>
                                    </p>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="left" v-if="task.status == 9 ">
                        <div class="top">
                            <span class="jd_name">当前进度：</span>
                            <span class="jd_status">调解中</span>
                            <span class="jd_sy">剩余</span>
                            <span class="jd_time">
                                <counter
                                    :endTime="task.appeal_end_time"
                                    @endCount="enrollEndEvent(task.tk)"
                                ></counter>
                            </span>
                        </div>

                        <div class="bottom">
                            <div class="icon">
                                <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img7.png'" />
                            </div>
                            <div class="content">
                                <template>
                                    <p class="title">工程极客发起了调解申请</p>
                                    <p
                                        class="desc"
                                    >请注意，执行您项目的工程极客发出了调解申请，请在3天内提交您的意见与证据。询龙网会根据双方所提交的意见与证据提出调解意见。您也可查看《询龙网服务协议》了解申请调解的相关流程与规则。</p>
                                    <p class="btns">
                                        <el-button
                                            type="mini"
                                            @click="appealDetail(task.tk,(task.appeal_deal.status==0)?0:1)"
                                            class="btn"
                                             style="width:124px;"
                                        >提交证明及资料</el-button>
                                    </p>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </template>

            <!--调解中 - 3天后-->
            <div
                class="left"
                v-if="task.status == 9  && task.appeal_deal && task.appeal_deal.status==1"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">调解中</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img7.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">请等待调解</p>
                            <p class="desc">询龙网邀请的第三方专家将根据双方所提交的意见与证据，给出调解意见，请耐心等待。</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    @click="appealDetail(task.tk)"
                                    class="btn"
                                    style="width:124px;"
                                >查看调解</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--调解中 - 收到调解意见-->
            <div
                class="left"
                v-if="task.status == 9  && task.appeal_deal && task.appeal_deal.status==2 && (task.appeal_deal.geek_status==0 && task.appeal_deal.employer_status==0)"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">调解中</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img7.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">已收到调解意见</p>
                            <p class="desc">您已经收到调解意见，请打开查看并确认。</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    @click="appealDetail(task.tk)"
                                    class="btn"
                                    style="width:124px;"
                                >查看调解意见</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <div
                class="left"
                v-if="task.status == 9 && task.appeal_deal && task.appeal_deal.status==2 && task.appeal_deal.geek_status!=2 && task.appeal_deal.employer_status!=2 && ((task.appeal_deal.geek_status==0 && task.appeal_deal.employer_status!=0 )|| (task.appeal_deal.geek_status!=0 && task.appeal_deal.employer_status==0 ))"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">调解中</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img7.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">已收到调解意见</p>
                            <p
                                class="desc"
                                v-if="task.appeal_deal.employer_status == 0"
                            >您已经收到调解意见，请打开查看并确认。</p>
                            <p class="desc" v-else>您已接受调解意见，请打开查看。</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    @click="appealDetail(task.tk)"
                                    class="btn"
                                    style="width:124px;"
                                >查看调解意见</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--调解中 - 双方达成一致-->
            <div
                class="left"
                v-if="task.status == 10  && task.appeal_deal && task.appeal_deal.status==2 && task.appeal_deal.geek_status==1 && task.appeal_deal.employer_status==1"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">调解完成</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img8.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">双方已接受调解意见</p>
                            <p class="desc">双方已接受调解意见，询龙网将按照调解意见对已托管的资金进行分配。</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    @click="appealDetail(task.tk)"
                                    class="btn"
                                     style="width:124px;"
                                >查看资金分配</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--调解中 - 双方未就调解达成一致-->
            <div
                class="left"
                v-if="task.status == 9  && task.appeal_deal && task.appeal_deal.status==2 && (task.appeal_deal.geek_status==2 || task.appeal_deal.employer_status==2)"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">调解中</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img7.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">双方未就调解达成一致</p>
                            <p class="desc">双方未就调解意见达成一致，请您尽快向北京仲裁委员会提交仲裁。并在收到仲裁书后，通过此处上传。</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    @click="appealDetail(task.tk)"
                                    class="btn"
                                     style="width:124px;"
                                >上传仲裁书</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--调解中 - 调解完成，分配资金完成-->
            <div
                class="left"
                v-if="task.status == 10  && task.appeal_deal && task.appeal_deal.status==3 && task.appeal_deal.deal_appeal_time"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">调解完成</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img8.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">资金分配完成</p>
                            <p class="desc">询龙网已按照北京仲裁委员会仲裁结果执行。</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    @click="appealDetail(task.tk)"
                                    class="btn"
                                     style="width:124px;"
                                >查看资金分配</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <div class="right">
                <p class="title">
                    <span class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/title_icon.png'" />
                    </span>
                    <span>项目信息</span>
                </p>

                <ul>
                    <li>
                        <span class="key">项目名称：</span>
                        <span class="value">{{ task.task_name }}</span>
                    </li>

                    <li>
                        <span class="key">项目编号：</span>
                        <span class="value">{{ task.sn }}</span>
                    </li>

                    <li>
                        <span class="key">项目模式：</span>
                        <span class="value">{{ task.task_type_value }}</span>
                    </li>

                    <!--项目有交易信息-->
                    <template v-if="task.trade">
                        <li>
                            <span class="key">履约保证金：</span>
                            <span class="value">{{ 1==task.trade.is_bond ? "需要托管" : "不需要托管" }}</span>
                        </li>
                        <li v-if="1==task.trade.is_bond">
                            <span class="key">履约保证金金额：</span>
                            <span class="value">{{ task.trade.bond }}元</span>
                        </li>
                        <li>
                            <span class="key">项目酬金：</span>
                            <span class="value">{{ task.trade.remuneration }} 元</span>
                        </li>
                    </template>
                    <template v-else>
                        <!-- <li>
                            <span class="key">履约保证金：</span>
                            <span class="value">{{ 1==task.is_bond ? "需要托管" : "不需要托管" }}</span>
                        </li> -->
                        <li v-if="1==task.is_bond">
                            <span class="key">履约保证金金额：</span>
                            <span class="value">{{ task.bond }}元</span>
                        </li>
                        <li>
                            <span class="key">项目酬金：</span>
                            <span class="value">{{ task.remuneration }} 元</span>
                        </li>
                    </template>
                </ul>

                <div class="btns">
                    <a href="javascript:;" @click="taskDetail(task.tk)" style="width:124px;">查看项目详情</a>
                </div>
            </div>
        </div>

        <!--工具栏-->
        <div class="f2">
            <ul>
                <li class="item">
                    <span class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/tools_icon1.png'" />
                    </span>
                    <span class="text">
                        <a
                            href="javascript:;"
                            @click="contractStep"
                            v-if="task.contract && (task.contract.status == 1 || task.contract.status == 2 || (task.contract.status == 0 && (task.contract.geek_address || task.contract.employer_address)))"
                            style="color:#587ddc;"
                        >电子合同签署中</a>
                        <a
                            href="javascript:;"
                            @click="contractStep"
                            v-else-if="task.contract && task.contract.status == 3"
                        >查看电子合同</a>
                        <a href="javascript:;" @click="contractStep" v-else>签署电子合同</a>
                    </span>
                </li>

                <li class="item" v-if="task.status>6 && task.status<9">
                    <span class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/tools_icon2.png'" />
                    </span>
                    <span class="text">
                        <a href="javascript:;" @click="appeal(task.tk)">提交调解申请</a>
                    </span>
                </li>

                <li class="item" v-else :disabled="true">
                    <span class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/tools_icon2.png'" />
                    </span>
                    <span class="text">
                        <a
                            href="javascript:;"
                            click="appeal(task.tk)"
                            style="cursor:not-allowed"
                        >提交调解申请</a>
                    </span>
                </li>

                <li class="item">
                    <span class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/tools_icon3.png'" />
                    </span>
                    <span class="text">
                        <a href="javascript:;" @click="viewFile(task.tk)">查看资料库</a>
                    </span>
                </li>

                <li
                    class="item"
                    v-if="(!task.trade || task.trade.status == -1 || task.trade.status == -2 || task.trade.status == 0 ) && task.status != 10 "
                >
                    <span class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/tools_icon4.png'" />
                    </span>
                    <span class="text">
                        <a href="javascript:;" @click="overTaskDialog = true">我要结束项目</a>
                    </span>
                </li>
            </ul>
        </div>

        <!--项目进度-->
        <div class="f3">
            <div
                style="width:100%;font-size:16px;margin-left:20px;height:40px;font-weight:normal;"
            >项目进度</div>
            <el-steps :active="task.employer_node_index" align-center>
                <el-step
                    v-for="(item, index) in task.employer_node"
                    :key="index"
                    :title="item.title"
                    :description="item.desc"
                ></el-step>
            </el-steps>
        </div>

        <!--项目状态-->
        <div class="f4">
            <!--报名中-->
            <div :class="task.status==3 && task.trade==''?'item':'item gray'">
                <div class="left">
                    <div class="block1">
                        <p class="title">报名</p>
                        <p class="num">01</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon1.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">报名</span>
                        <span class="datetime">{{ task.approve_time }}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">您所发布的项目已经通过审核，正式发布。</p>
                        <p class="text">
                            报名时间段：{{ task.approve_time }} 至
                            {{ task.end_time_value }}
                        </p>
                    </div>
                </div>

                <div class="right" v-if="task.enroll && task.enroll_count > 0">
                    <div class="content">
                        <span>报名人数：</span>
                        <span>{{ task.enroll.length }}人</span>
                    </div>
                    <div class="btns">
                        <el-button
                            type="mini"
                            class="btn"
                            @click="isShowDraw = true"
                            style="width:124px;"
                        >查看及邀请</el-button>
                    </div>
                </div>

                <div class="right" v-else>
                    <div class="content">
                        <span>报名人数：</span>
                        <span>0人</span>
                    </div>
                    <div class="btns">
                        <el-button type="mini" class="btn" disabled style="width:124px;">查看及邀请</el-button>
                    </div>
                </div>
            </div>

            <!--项目结束 - 极客报名7天内雇主未发起邀请-->
            <div
                :class="task.status == 10 && task.enroll && task.finish_description=='' && task.trade==''?'item':'item gray'"
                v-if="task.status == 10 && task.enroll && task.finish_description=='' && task.trade==''"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">结束</p>
                        <p class="num"></p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon5.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">项目结束</span>
                        <span class="datetime">{{task.appeal_deal.deal_appeal_time}}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">项目报名截止7天内雇主未发起邀请。</p>
                        <!-- <p class="text" style="color:#587ddc">{{task.finish_description}}</p> -->
                    </div>
                </div>

                <div class="right">
                    <div class="btns"></div>
                </div>
            </div>

            <!--邀请信息列表-->
            <div v-for="(item,idx) in task.trade_list" :key="idx">
                <div class="item gray" v-if="-1 == item.status || -2 == item.status">
                    <div class="left">
                        <div class="block1">
                            <p class="title">邀请</p>
                            <p class="num">02</p>
                        </div>
                        <div class="block2"></div>
                        <div class="cir">
                            <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon2.png'" />
                        </div>
                    </div>

                    <div class="center">
                        <div class="top">
                            <!-- <span class="title">邀请</span> -->
                            <span class="title" style="color:red;" v-if="-1==item.status">该邀请被拒绝</span>
                            <span class="title" style="color:red;" v-if="-2==item.status">确认邀请超时</span>
                            <span class="datetime">{{item.create_time}}</span>
                        </div>
                        <div class="bottom">
                            <p class="text">已邀请的工程极客</p>
                            <p class="avatar">
                                <img :src="util.reImg(item.avatar)" />
                            </p>
                            <p class="text">
                                <a
                                    class="link"
                                    href="javascript:;"
                                    @click="geekDetail(item.uk)"
                                     style="width:124px;"
                                >{{ item.name }}</a>
                            </p>
                            <p class="text" v-if="-2==item.status">在限定时间内没有回应，您可以重新向已报名的工程极客发起邀请。</p>
                            <p class="text" v-if="-1==item.status">您发出的邀请被拒绝了，您可以重新向已报名的工程极客发起邀请。</p>
                        </div>
                    </div>

                    <div class="right">
                        <div class="btns" v-if="task.trade">
                            <el-button
                                type="mini"
                                class="btn"
                                @click="showInvite(item.trade_sn)"
                                style="width:124px;"
                            >邀请详情</el-button>
                        </div>
                    </div>
                </div>

                <div class="item gray" v-if="-4 == item.status || -5 == item.status">
                    <div class="left">
                        <div class="block1">
                            <p class="title">工作</p>
                            <p class="num">02</p>
                        </div>
                        <div class="block2"></div>
                        <div class="cir">
                            <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon2.png'" />
                        </div>
                    </div>

                    <div class="center">
                        <div class="top">
                            <span
                                class="title"
                                style="color:red;"
                                v-if="-4==item.status"
                            >工程极客缴纳履约保证金超时</span>
                            <span class="title" style="color:red;" v-if="-5==item.status">您托管酬金超时</span>
                            <span class="datetime">{{item.create_time}}</span>
                        </div>
                        <div class="bottom">
                            <p class="text">已邀请的工程极客</p>
                            <p class="avatar">
                                <img :src="util.reImg(item.avatar)" />
                            </p>
                            <p class="text">
                                <a
                                    class="link"
                                    href="javascript:;"
                                    @click="geekDetail(item.uk)"
                                     style="width:124px;"
                                >{{ item.name }}</a>
                            </p>
                        </div>
                    </div>

                    <div class="right">
                        <!-- <div class="content">
                            <span v-if="-1==item.status">工程极客该邀请被拒绝</span>
                            <span v-if="-2==item.status">工程极客确认邀请超时</span>
                        </div>-->
                        <div class="btns" v-if="task.trade">
                            <el-button
                                type="mini"
                                class="btn"
                                @click="showInvite(item.trade_sn)"
                                style="width:124px;"
                            >邀请详情</el-button>
                        </div>
                    </div>
                </div>
            </div>

            <!--已发出邀请-->
            <div
                :class="task.status==3 && task.trade && task.trade.status==0?'item':'item gray'"
                v-if="task && task.trade && task.trade.status==0"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">邀请</p>
                        <p class="num">02</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon1.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">发出邀请</span>
                        <span class="datetime">{{task.trade.create_time}}</span>
                    </div>
                    <div class="bottom" v-if="task.trade && task.trade.geek">
                        <p class="text">已邀请的工程极客</p>
                        <p class="avatar">
                            <img :src="util.reImg(task.trade.geek.avatar)" />
                        </p>
                        <p class="text">
                            <a
                                class="link"
                                href="javascript:;"
                                @click="geekDetail(task.trade.geek.uk)"
                                 style="width:124px;"
                            >{{ task.trade.geek.name }}</a>
                        </p>
                    </div>
                </div>

                <div class="right">
                    <div class="content">
                        <span>待对方确认</span>
                    </div>
                    <div class="btns">
                        <el-button
                            type="mini"
                            class="btn"
                            @click="showInvite(task.trade.trade_sn)"
                             style="width:124px;"
                        >查看邀请</el-button>
                    </div>
                </div>
            </div>

            <!--已接受邀请-->
            <div
                :class="task.status==4 && task.trade && task.trade.status==0 ?'item':'item gray'"
                v-if="task && task.trade && task.trade.status >= 2"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">邀请</p>
                        <p class="num">02</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon2.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">邀请已接受</span>
                        <span class="datetime">{{task.trade.create_time}}</span>
                    </div>
                    <div class="bottom" v-if="task.trade.geek">
                        <p class="text">已邀请的工程极客</p>
                        <p class="avatar">
                            <img :src="util.reImg(task.trade.geek.avatar)" />
                        </p>
                        <p class="text">
                            <a
                                class="link"
                                href="javascript:;"
                                @click="geekDetail(task.trade.geek.uk)"
                            >{{ task.trade.geek.name }}</a>
                        </p>
                    </div>
                </div>

                <div class="right">
                    <div class="content">
                        <span>工程极客已接受邀请</span>
                    </div>
                    <div class="btns">
                        <el-button
                            type="mini"
                            class="btn"
                            @click="showInvite(task.trade.trade_sn)"
                            style="width:124px;"
                        >查看邀请</el-button>
                    </div>
                </div>
            </div>

            <!--托管酬金-->
            <div
                :class="task.status==6 && task.trade && task.trade.status==2 ?'item':'item gray'"
                v-if="task && task.trade && task.trade.status >= 2 && task.trade.is_remuneration==1"
                style="height:210px;"
            >
                <div class="left" style="height:210px;">
                    <div class="block1" style="height:210px;">
                        <p class="title">托管</p>
                        <p class="num">03</p>
                    </div>
                    <div class="block2" style="height:210px;"></div>
                    <div class="cir" style="top: 55px;">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon3.png'" />
                    </div>
                </div>
                <div class="center" style="height:210px;">
                    <div class="top">
                        <span class="title">托管资金情况</span>
                        <span class="datetime">{{task.trade.create_time}}</span>
                    </div>

                    <div class="bottom">
                        <table style="width:100%;" cellspacing="0">
                            <tbody style="width:100%;">
                                <tr class="li" v-if="task.employer">
                                    <td style="width:80px;padding:5px;">雇主</td>
                                    <td style="padding-left:5px;" colspan="2">
                                        <a
                                            class="link"
                                            href="javascript:;"
                                            @click="employerDetail(task.employer.uk)"
                                            style="color:#587ddc"
                                        >{{ task.employer.name }}</a>
                                    </td>
                                </tr>
                                <tr class="li" v-if="task.employer">
                                    <td
                                        style="width:80px;border-bottom:1px solid #ddd;padding:5px;"
                                    >托管酬金</td>

                                    <td style="padding-left:5px;border-bottom:1px solid #ddd;">
                                        <span>{{ task.trade.remuneration }} 元</span>
                                    </td>

                                    <td
                                        style="text-align:right;border-bottom:1px solid #ddd;width: 210px;color:#50e878"
                                        v-if="task.trade && task.trade.is_remuneration != 1"
                                    >
                                        <div style="width: 210px;color:#666;">无需托管</div>
                                    </td>

                                    <td
                                        style="text-align:right;border-bottom:1px solid #ddd;width: 210px;color:#50e878"
                                        v-if="task.trade && task.trade.is_remuneration == 1 && task.trade.remuneration_time > 0"
                                    >
                                        <div
                                            style="width: 210px;color:#666;"
                                        >{{task.trade.remuneration_time_value}} 已托管</div>
                                    </td>

                                    <td
                                        style="text-align:right;border-bottom:1px solid #ddd;width: 210px;color:red"
                                        v-if="task.trade && task.trade.is_remuneration == 1 && task.trade.remuneration_time <= 0"
                                    >
                                        <div style="width: 210px;color:#666;">未托管</div>
                                    </td>

                                    <td
                                        style="text-align:right;border-bottom:1px solid #ddd;width: 210px;color:red"
                                        v-if="task.trade && task.trade.is_remuneration == 1 && task.trade.status == -5"
                                    >
                                        <div style="width: 210px;color:#666;">托管超时</div>
                                    </td>
                                </tr>

                                <tr class="li" v-if="task.trade && task.trade.geek">
                                    <td style="width:80px;padding:5px;">极客</td>
                                    <td style="padding-left:5px;" colspan="2">
                                        <a
                                            class="link"
                                            href="javascript:;"
                                            @click="geekDetail(task.trade.geek.uk)"
                                            style="color:#587ddc"
                                        >{{ task.trade.geek.name }}</a>
                                    </td>
                                </tr>
                                <tr class="li" v-if="task.trade && task.trade.geek">
                                    <td
                                        style="width:80px;border-bottom:1px solid #ddd;padding:5px;"
                                    >履约保证金</td>

                                    <td
                                        style="padding-left:5px;border-bottom:1px solid #ddd;width:180px;"
                                    >
                                        <span>{{ task.trade.bond }} 元</span>
                                    </td>

                                    <td
                                        style="text-align:right;border-bottom:1px solid #ddd;width: 210px;color:red"
                                        v-if="task.trade && task.trade.is_bond == 1 && task.trade.bond_time <= 0"
                                    >
                                        <div style="width: 210px;color:#666;">未托管</div>
                                    </td>

                                    <td
                                        style="text-align:right;border-bottom:1px solid #ddd;width: 210px;"
                                        v-if="task.trade &&task.trade.is_bond == 1 &&task.trade.bond_time > 0"
                                    >
                                        <div
                                            style="width: 210px;color:#666;"
                                        >{{task.trade.bond_time_value}} 已托管</div>
                                    </td>

                                    <td
                                        style="text-align:right;border-bottom:1px solid #ddd;width: 210px;color:#50e878"
                                        v-if="task.trade && task.trade.is_bond != 1"
                                    >
                                        <div style="width: 210px;color:#666;">无需托管</div>
                                    </td>

                                    <td
                                        style="text-align:right;border-bottom:1px solid #ddd;width: 210px;color:red"
                                        v-if="task.trade &&task.trade.is_bond == 1 &&task.trade.status == -5"
                                    >
                                        <div style="width: 210px;color:#666;">托管超时</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="right" style="height:210px;line-height: 136px;">
                    <div class="content">
                        <template v-if="task.trade && task.trade.is_remuneration==1">
                            <span v-if="task.trade && task.trade.remuneration_time>0">您已完成托管</span>
                            <el-button
                                type="mini"
                                class="btn"
                                v-else
                                @click="payTypeSelectDialog = true"
                                 style="width:124px;"
                            >
                                <span>立即托管</span>
                            </el-button>
                        </template>
                        <template v-else>
                            <span>无需托管</span>
                        </template>
                    </div>
                    <div class="btns" v-if="task.trade && task.trade.is_remuneration==1"></div>
                </div>
            </div>

            <!--工作中-->
            <div
                :class="task.status==7 && task.trade && task.trade.status==3 ?'item':'item gray'"
                v-if="task && task.trade && task.trade.status >= 3 && task.trade.is_remuneration==1"
                id="progress_box_position"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">工作</p>
                        <p class="num">04</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon3.png'" />
                    </div>
                </div>
                <div class="center" style="position:relative;">
                    <div class="top">
                        <span class="title">工作中</span>
                        <span class="datetime">{{task.trade.create_time}}</span>
                    </div>

                    <div class="bottom">
                        <p class="text">已邀请的工程极客</p>
                        <p class="avatar">
                            <img :src="util.reImg(task.trade.geek.avatar)" />
                        </p>
                        <p class="text">
                            <a
                                class="link"
                                href="javascript:;"
                                @click="geekDetail(task.trade.geek.uk)"
                            >{{ task.trade.geek.name }}</a>
                        </p>
                        <p class="text">正在为您执行这个项目，您可以打开下方卷展栏查看进度并支付酬金</p>
                    </div>
                    <div class="tools" v-if="task_progress_box == false">
                        <el-button @click="task_progress_view">== 展开查看支付详情 ==</el-button>
                    </div>
                    <div class="tools" v-if="task_progress_box == true">
                        <el-button @click="task_progress_view">== 收起查看支付详情 ==</el-button>
                    </div>
                </div>

                <div class="right">
                    <div class="btns">
                        <el-button
                            type="mini"
                            class="btn"
                            @click="task_progress_view"
                            style="width:124px;"
                        >查看进度</el-button>
                    </div>
                </div>
            </div>

            <!-- 工作中-项目进度 -->
            <div
                class="td-item-box step1-box step4-box"
                v-if="task_progress_box == true && task && task.trade && task.trade.status >= 3 && task.trade.is_remuneration==1"
                style="border:1px solid #eee;"
            >
                <div class="progress_box_left">
                    <div class="br-bd" v-if="task && task.trade">
                        <el-timeline>
                            <!-- 分阶段支付已支付状态 通过在 el-timeline-item上添加class="active" 来区分开始状态，通过设置button按钮的type来控制button颜色，是否可点用:disabled="true"或:disabled="false"控制-->
                            <el-timeline-item
                                class="active"
                                :timestamp="item | doneTimeFilter"
                                placement="top"
                                v-for="(item, index) in task.trade.block_employer"
                                :key="index"
                            >
                                <el-card>
                                    <div class="card-bd">
                                        <div class="cardbd-l">
                                            <h4 class="tit mb-10" v-text="item.title"></h4>
                                            <p>
                                                支付酬金：
                                                <span class="color1">￥{{item.remuneration}} 元</span>
                                            </p>
                                            <template v-if="task.appeal_deal">
                                                <p v-if="item.status==-1">阶段状态：已结束</p>
                                                <p v-if="item.status==0">阶段状态：调解</p>
                                                <p v-if="item.status==1">阶段状态：已提交阶段成果</p>
                                                <p v-if="item.status==2">阶段状态：正在申请酬金</p>
                                                <p v-if="item.status==3">阶段状态：酬金已支付完成（{{item.done_time}}）</p>
                                            </template>
                                            <template v-else>
                                                <p v-if="item.status==0">阶段状态：阶段工作未开始</p>
                                                <p v-if="item.status==1">阶段状态：已提交阶段成果</p>
                                                <p v-if="item.status==2">阶段状态：正在申请酬金</p>
                                                <p v-if="item.status==3">阶段状态：酬金已支付完成（{{item.done_time}}）</p>
                                                <p v-if="item.status==10">
                                                    阶段状态：
                                                    <span style="color:#16ec16">阶段工作进行中</span>
                                                </p>
                                            </template>
                                        </div>

                                        <div class="cardbd-r" v-if="task.appeal_deal">
                                            <template v-if="item.status == 3">
                                                <div class="btn-item mb-10">
                                                    <el-button disabled type="primary">已支付酬金</el-button>
                                                </div>
                                                <div class="btn-item mb-10">
                                                    <el-button
                                                        type="primary"
                                                        @click="viewResult(task.tk,item.id)"
                                                    >查看阶段成果</el-button>
                                                </div>
                                            </template>

                                            <template v-if="item.status == 2">
                                                <div class="btn-item mb-10">
                                                    <el-tooltip
                                                        effect="dark"
                                                        content="工程极客尚未执行“申请支付酬金”的操作，请等待工程极客申请后，方可进行支付"
                                                        placement="top"
                                                    >
                                                        <el-button
                                                            style="background: #ababab !important;"
                                                        >支付酬金</el-button>
                                                    </el-tooltip>
                                                </div>
                                                <div class="btn-item mb-10">
                                                    <el-button
                                                        type="primary"
                                                        @click="viewResult(task.tk,item.id)"
                                                    >查看阶段成果</el-button>
                                                </div>
                                            </template>

                                            <template v-if="item.status==10">
                                                <div class="btn-item mb-10">
                                                    <el-button type="primary" disabled>争议阶段</el-button>
                                                </div>
                                            </template>

                                            <template v-if="item.status == 1">
                                                <div class="btn-item mb-10">
                                                    <el-tooltip
                                                        effect="dark"
                                                        content="工程极客尚未执行“申请支付酬金”的操作，请等待工程极客申请后，方可进行支付"
                                                        placement="top"
                                                    >
                                                        <el-button
                                                            style="background: #ababab !important;"
                                                        >支付酬金</el-button>
                                                    </el-tooltip>
                                                </div>
                                                <div class="btn-item mb-10">
                                                    <el-button
                                                        type="primary"
                                                        @click="viewResult(task.tk,item.id)"
                                                    >查看阶段成果</el-button>
                                                </div>
                                            </template>

                                            <template v-if="item.status==0">
                                                <div class="btn-item mb-10">
                                                    <el-button type="primary" disabled>非争议阶段</el-button>
                                                    <el-button
                                                        style="color:#ef6565"
                                                        disabled
                                                    >Tips:调节后退还</el-button>
                                                </div>
                                            </template>

                                            <template v-if="item.status==-1">
                                                <div class="btn-item mb-10">
                                                    <el-button type="primary" disabled>已退回非争议酬金</el-button>
                                                </div>
                                            </template>
                                        </div>

                                        <div class="cardbd-r" v-else>
                                            <template v-if="item.status == 3">
                                                <div class="btn-item mb-10">
                                                    <el-button disabled type="primary">已支付酬金</el-button>
                                                </div>
                                                <div class="btn-item mb-10">
                                                    <el-button
                                                        type="primary"
                                                        @click="viewResult(task.tk,item.id)"
                                                    >查看阶段成果</el-button>
                                                </div>
                                            </template>

                                            <template v-if="item.status==10">
                                                <div class="btn-item mb-10">
                                                    <el-tooltip
                                                        effect="dark"
                                                        content="工程极客尚未执行“申请支付酬金”的操作，请等待工程极客申请后，方可进行支付"
                                                        placement="top"
                                                    >
                                                        <el-button
                                                            style="background: #ababab !important;"
                                                        >支付酬金</el-button>
                                                    </el-tooltip>
                                                </div>
                                                <div class="btn-item mb-10">
                                                    <el-button
                                                        type="primary"
                                                        @click="viewResult(task.tk,item.id)"
                                                    >查看阶段成果</el-button>
                                                </div>
                                            </template>

                                            <template v-if="item.status == 2">
                                                <div class="btn-item mb-10">
                                                    <el-button
                                                        type="primary"
                                                        @click="showPayRemDialog(item.remuneration,item.id)"
                                                    >支付酬金</el-button>
                                                </div>
                                                <div class="btn-item mb-10">
                                                    <el-button
                                                        type="primary"
                                                        @click="viewResult(task.tk,item.id)"
                                                    >查看阶段成果</el-button>
                                                </div>
                                            </template>

                                            <template v-if="item.status == 1">
                                                <div class="btn-item mb-10">
                                                    <el-tooltip
                                                        effect="dark"
                                                        content="工程极客尚未执行“申请支付酬金”的操作，请等待工程极客申请后，方可进行支付"
                                                        placement="top"
                                                    >
                                                        <el-button
                                                            style="background: #ababab !important;"
                                                        >支付酬金</el-button>
                                                    </el-tooltip>
                                                </div>
                                                <div class="btn-item mb-10">
                                                    <el-button
                                                        type="primary"
                                                        @click="viewResult(task.tk,item.id)"
                                                    >查看阶段成果</el-button>
                                                </div>
                                            </template>

                                            <template v-if="item.status == 0">
                                                <div class="btn-item mb-10">
                                                    <el-tooltip
                                                        effect="dark"
                                                        content="工程极客尚未执行“申请支付酬金”的操作，请等待工程极客申请后，方可进行支付"
                                                        placement="top"
                                                    >
                                                        <el-button
                                                            style="background: #ababab !important;"
                                                        >支付酬金</el-button>
                                                    </el-tooltip>
                                                </div>
                                                <div class="btn-item mb-10">
                                                    <el-button disabled type="primary">未提交阶段成果</el-button>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </el-card>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                    <div style="clear:both;width:100%;height:1px;"></div>
                </div>

                <div class="ver-line progress_box_right">
                    <h3 class="it-tit bl-hd">项目进度</h3>
                    <div class="bl-bd">
                        <div class="des-it">
                            已支付酬金：
                            <span class="color1">{{ task.payed_remuneration_employer }} 元</span>
                        </div>
                        <!-- <div class="des-it">
                            平台服务费：
                            <span class="color1">5%</span>
                        </div>-->
                    </div>
                </div>
            </div>

            <!--工作已完成-->
            <div
                :class="task.status==8 && task.trade && task.trade.status==4 ?'item':'item gray'"
                v-if="task.status==8 && task.trade && task.trade.status==4 && task.trade.is_remuneration==1"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">完成</p>
                        <p class="num">05</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon4.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">项目完成</span>
                        <span class="datetime">{{task.trade.create_time}}</span>
                    </div>
                    <div class="bottom" v-if="task.trade.geek">
                        <p class="text">工程极客</p>
                        <p class="avatar">
                            <img :src="util.reImg(task.trade.geek.avatar)" />
                        </p>
                        <p class="text">
                            <a
                                class="link"
                                href="javascript:;"
                                @click="geekDetail(task.trade.geek.uk)"
                            >{{ task.trade.geek.name }}</a>
                        </p>
                        <p class="text">已完成了项目，别忘记评价对方的表现哦！</p>
                    </div>
                </div>

                <div class="right">
                    <div class="content" v-if="task.appraise">
                        <span v-if="task.appraise.geek_appraise_time>0">对方已评价</span>
                        <span v-else>对方未评价</span>
                    </div>
                    <div class="content" v-else>
                        <span>没有评价信息</span>
                    </div>
                    <div class="btns">
                        <el-button
                            type="mini"
                            class="btn"
                            v-if="task.appraise.employer_appraise_time>0"
                            @click="view_appraise(task.tk)"
                             style="width:124px;"
                        >查看评价</el-button>
                        <el-button type="mini" class="btn" v-else @click="task_appraise(task.tk)" style="width:124px;">评价</el-button>
                    </div>
                </div>
            </div>

            <!--项目结束 - 雇主托管酬金超时-->
            <div
                :class="task.status == 10 && task.trade && task.trade.status==-5?'item':'item gray'"
                v-if="task.status == 10 && task.trade && task.trade.status==-5"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">结束</p>
                        <p class="num"></p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon5.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">项目结束</span>
                        <span class="datetime">{{task.appeal_deal.deal_appeal_time}}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">您未在规定时间内托管项目酬金，项目自动结束。</p>
                        <p class="text" style="color:#587ddc">{{task.finish_description}}</p>
                    </div>
                </div>

                <div class="right">
                    <div class="btns"></div>
                </div>
            </div>

            <!--项目结束 - 雇主托管酬金超时-->
            <div
                :class="task.status == 10 && task.trade && task.trade.status==-4?'item':'item gray'"
                v-if="task.status == 10 && task.trade && task.trade.status==-4"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">结束</p>
                        <p class="num"></p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon5.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">项目结束</span>
                        <span class="datetime">{{task.appeal_deal.deal_appeal_time}}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">工程极客未在规定时间内托管履约保证金，项目自动结束。</p>
                        <p class="text" style="color:#587ddc">{{task.finish_description}}</p>
                    </div>
                </div>

                <div class="right">
                    <div class="btns"></div>
                </div>
            </div>

            <!--项目结束 - 线下交易-->
            <div
                :class="task.status==10 && task.trade && task.trade.status==6 ?'item':'item gray'"
                v-if="task.status==10 && task.trade && task.trade.status==6 && task.trade.is_remuneration!=1"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">结束</p>
                        <p class="num">03</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon4.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">项目结束</span>
                        <span class="datetime">{{task.trade.create_time}}</span>
                    </div>
                    <div class="bottom" v-if="task.trade.geek">
                        <p class="text">已邀请到工程极客</p>
                        <p class="avatar">
                            <img :src="util.reImg(task.trade.geek.avatar)" />
                        </p>
                        <p class="text">
                            <a
                                class="link"
                                href="javascript:;"
                                @click="geekDetail(task.trade.geek.uk)"
                            >{{ task.trade.geek.name }}</a>
                        </p>
                        <p class="text">同意与您线下协商项目，此项目已结束！</p>
                    </div>
                </div>
            </div>

            <!--项目结束 - 项目截止报名 - 没有工程极客报名-->
            <div
                :class="task.status==10 && task.enroll=='' ?'item':'item gray'"
                v-if="task.status==10 && task.enroll=='' && task.log_current && (task.log_current.code < 31 || task.log_current.code > 37)"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">结束</p>
                        <p class="num">02</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon5.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">项目结束</span>
                        <span class="datetime">{{task.update_time_value}}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">项目报名已截止，没有任何工程极客报名这个项目，此项目已自动结束。</p>
                    </div>
                </div>

                <div class="right"></div>
            </div>

            <!--项目结束 - 雇主主动结束项目-->
            <div
                :class="task.status==10 && task.log_current && task.log_current.code>=31  && task.log_current.code<=37?'item':'item gray'"
                v-if="task.status==10 && task.log_current && task.log_current.code>=31 && task.log_current.code<=37"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">结束</p>
                        <p class="num"></p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon5.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">项目结束</span>
                        <span class="datetime">{{task.log_current.create_time}}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">由于您主动结束了项目，此项目目前已结束。您所提交的结束原因如下：</p>
                        <p class="text" style="color:#587ddc">{{task.finish_description}}</p>
                    </div>
                </div>

                <div class="right"></div>
            </div>

            <!--项目结束 - 平台结束项目-->
            <div
                :class="task.status==10 && task.finish_admin>0 && task.finish_time>0?'item':'item gray'"
                v-if="task.status==10 && task.finish_admin>0 && task.finish_time>0"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">结束</p>
                        <p class="num"></p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon5.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">项目结束</span>
                        <span class="datetime">{{task.finish_time_text}}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">该项目被平台强制结束，此项目目前已结束。结束原因如下：</p>
                        <p class="text" style="color:#587ddc">{{task.finish_description}}</p>
                    </div>
                </div>

                <div class="right"></div>
            </div>

            <!--调解发起 3天内-->
            <div
                :class="task.appeal_deal && task.appeal_deal.status==0?'item':'item gray'"
                v-if="task.appeal_deal"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">调解</p>
                        <p class="num">05</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon4.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">发起调解</span>
                        <span class="datetime">{{task.appeal_deal.create_time}}</span>
                    </div>
                    <!--雇主发起-->
                    <template v-if="task.appeal[0].uk==userInfo.info.uk">
                        <div class="bottom" v-if="task.employer">
                            <p
                                class="text"
                            >您已发起了调解申请，请在72小时（3天）内提交调解证明及资料。调解证明资料提交截止时间：{{task.appeal_end_time_value}}</p>
                        </div>
                    </template>

                    <!--工程极客发起-->
                    <template v-else>
                        <div class="bottom" v-if="task.trade && task.trade.geek">
                            <p class="text">工程极客</p>
                            <p class="avatar">
                                <img :src="util.reImg(task.trade.geek.avatar)" />
                            </p>
                            <p class="text">
                                <a
                                    class="link"
                                    href="javascript:;"
                                    @click="geekDetail(task.trade.geek.uk)"
                                     style="width:124px;"
                                >{{ task.trade.geek.name }}</a>
                            </p>
                            <p
                                class="text"
                            >已发起了调解申请，请在72小时（3天）内提交调解证明及资料。调解证明资料提交截止时间：{{task.appeal_end_time_value}}</p>
                        </div>
                    </template>
                </div>
                <div class="right">
                    <div class="btns">
                        <el-button
                            type="mini"
                            class="btn"
                            @click="appealDetail(task.tk,(task.appeal_deal.status==0)?0:1)"
                             style="width:124px;"
                        >提交证明及资料</el-button>
                    </div>
                </div>
            </div>

            <!--调解发起 3天后 - 等待调解-->
            <div
                :class="task.appeal_deal && task.appeal_deal.status==1?'item':'item gray'"
                v-if="task.appeal_deal && task.appeal_deal.status>=1"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">调解</p>
                        <p class="num">06</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon4.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">调解阶段</span>
                        <span class="datetime">{{task.appeal_deal.add_appeal_end}}</span>
                    </div>
                    <div class="bottom" v-if="task.employer">
                        <p class="text">询龙网邀请的第三方专家将根据双方所提交的意见与证据，给出调解意见，请耐心等待。</p>
                    </div>
                </div>
                <div class="right">
                    <div class="btns">
                        <el-button
                            type="mini"
                            class="btn"
                            @click="appealDetail(task.tk)"
                            style="width:124px;"
                        >查看调解</el-button>
                    </div>
                </div>
            </div>

            <!--调解 - 收到调解意见-->
            <div
                :class="task.appeal_deal && (task.appeal_deal.geek_status==0 || task.appeal_deal.employer_status==0) &&  task.appeal_deal.status>=2?'item':'item gray'"
                v-if="task.appeal_deal && task.appeal_deal.content && task.appeal_deal.status>=2"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">调解</p>
                        <p class="num">07</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon4.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">收到调解意见</span>
                        <span class="datetime">{{task.appeal_deal.content_time}}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">您已经收到调解意见，请打开查看并确认。</p>
                    </div>
                </div>

                <div class="right">
                    <div class="btns">
                        <el-button
                            type="mini"
                            class="btn"
                            @click="appealDetail(task.tk)"
                            style="width:124px;"
                        >查看调解意见</el-button>
                    </div>
                </div>
            </div>

            <!--调解 - 双方未达成一致-->
            <div
                :class="task.appeal_deal&& (task.appeal_deal.geek_status==2 || task.appeal_deal.employer_status==2) && task.appeal_deal.status!=3?'item':'item gray'"
                v-if="task.appeal_deal&& (task.appeal_deal.geek_status==2 || task.appeal_deal.employer_status==2) && task.appeal_deal.status>=2"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">调解</p>
                        <p class="num">08</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon4.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">处理调解</span>
                        <span
                            class="datetime"
                        >{{task.appeal_deal.geek_deal_time?task.appeal_deal.geek_deal_time:task.appeal_deal.employer_deal_time}}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">双方未就调解意见达成一致，请您尽快向北京仲裁委员会提交仲裁。并在收到仲裁书后，通过此处上传。</p>
                    </div>
                </div>
                <div class="right">
                    <div class="btns">
                        <el-button type="mini" class="btn" @click="appealDetail(task.tk)" style="width:124px;">上传仲裁书</el-button>
                    </div>
                </div>
            </div>

            <!--调解中 - 双方达成一致-->
            <div
                :class="task.appeal_deal && task.appeal_deal.geek_status==1 && task.appeal_deal.employer_status==1 && task.appeal_deal.status==3?'item':'item gray'"
                v-if="task.appeal_deal && task.appeal_deal.geek_status==1 && task.appeal_deal.employer_status==1 && task.appeal_deal.status==3"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">调解</p>
                        <p class="num">08</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon4.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">处理调解</span>
                        <span
                            class="datetime"
                        >{{task.appeal_deal.geek_deal_time?task.appeal_deal.geek_deal_time:task.appeal_deal.employer_deal_time}}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">双方已接受调解意见，询龙网将按照调解意见对已托管的资金进行分配。</p>
                    </div>
                </div>
                <div class="right">
                    <div class="btns">
                        <el-button type="mini" class="btn" @click="appealDetail(task.tk)" style="width:124px;">查看资金分配</el-button>
                    </div>
                </div>
            </div>

            <!--调解完成 - 项目结束-->
            <div
                :class="(task.status==10 && task.appeal_deal && task.appeal_deal.status==3) ?'item':'item gray'"
                v-if="task.status==10 && task.appeal_deal && task.appeal_deal.status==3"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">结束</p>
                        <p class="num"></p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon5.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">项目结束</span>
                        <span class="datetime">{{task.appeal_deal.deal_appeal_time}}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">调解完成，资金分配已完成，项目结束。</p>
                        <p class="text" style="color:#587ddc">{{task.finish_description}}</p>
                    </div>
                </div>

                <div class="right">
                    <div class="btns">
                        <el-button type="mini" class="btn" @click="appealDetail(task.tk)" style="width:124px;">查看资金分配</el-button>
                    </div>
                </div>
            </div>
        </div>

        <!-- 雇主 - 右侧抽屉 - 报名列表 -->
        <el-drawer
            v-if="userInfo.info.user_type == 2"
            custom-class="list-draw enroll-limit"
            :before-close="closeDraw"
            :visible="isShowDraw"
            type="primary"
            title="报名极客列表"
            size="550px"
        >
            <div style="overflow-y:scroll;height:96%;width:100%;">
                <div class="td-item-box step2-box" v-for="(item,index) in task.enroll" :key="index">
                    <div class="box-l">
                        <header class="bl-hd">
                            <div class="bl-hd-l">
                                <div class="avator-box">
                                    <div class="images-box">
                                        <el-image :src="util.reImg(item.geek.avatar)">
                                            <div slot="error" class="image-slot">
                                                <i class="el-icon-picture-outline"></i>
                                            </div>
                                        </el-image>
                                    </div>
                                </div>
                            </div>
                            <div class="bl-hd-r">
                                <el-row :span="24">
                                    <div class="it-ti tit" v-text="item.geek.name"></div>
                                    <div class="des-it desc">
                                        完成项目：{{ item.geek.task_total }} 次 /
                                        获得酬劳：{{ item.geek.reward }}
                                    </div>
                                </el-row>

                                <el-row :span="24">
                                    <div class="it-ti" v-text="item.geek.area_value"></div>
                                    <div class="des-it">
                                        <div class="free-box btn-box">
                                            <el-button
                                                class="check-file"
                                                @click="geekDetail(item.geek.uk,'isShowDraw')"
                                            >查看资料</el-button>

                                            <el-button
                                                class="contact-ta"
                                                @click="sendMessage(item.geek.uk,'isShowDraw')"
                                            >联系Ta</el-button>

                                            <template v-if="task.status==3 || task.status==4">
                                                <el-button
                                                    type="primary"
                                                    v-if="2==item.status"
                                                    disabled="true"
                                                >已邀请</el-button>

                                                <el-button
                                                    type="primary"
                                                    v-if="1==item.status"
                                                    @click="invite_user(task.tk,item.geek.uk,'isShowDraw')"
                                                >立即邀请</el-button>

                                                <el-button
                                                    type="primary"
                                                    v-if="-1==item.status"
                                                    @click="invite_user(task.tk,item.geek.uk,'isShowDraw')"
                                                >立即邀请</el-button>

                                                <el-button
                                                    type="primary"
                                                    v-if="-2==item.status"
                                                    @click="invite_user(task.tk,item.geek.uk,'isShowDraw')"
                                                >立即邀请</el-button>
                                            </template>
                                        </div>
                                    </div>
                                </el-row>
                            </div>
                        </header>
                        <div class="bl-bd">
                            <div style="height:35px;font-weight:normal;">报名信息</div>
                            <div class="des-it">
                                <template>
                                    <span>报价：</span>
                                    <b style="margin-right:20px;">{{ item.price }}元</b>
                                </template>

                                <template v-if="task.task_type == 3">
                                    <span>工期：</span>
                                    <b style="margin-right:20px;">{{ item.work_time }}天</b>
                                    <span>每天平均酬金：</span>
                                    <b
                                        style="margin-right:20px;"
                                    >{{(item.price / item.work_time).toFixed(2)}}元/天</b>
                                </template>

                                <!--
                                <template>
                                    <span>联系人：</span>
                                    <b style="margin-right:20px;">{{item.contact}}</b>
                                </template>

                                <template>
                                    <span>联系电话：</span>
                                    <b style="margin-right:20px;">{{item.phone}}</b>
                                </template>-->

                                <template>
                                    <span>附件：</span>
                                    <b style="margin-right:20px;" v-if="item.annex_zip">
                                        <el-link
                                            :href="websiteConfigs.sourceUrl +item.annex_zip"
                                            style="font-size: 12px !important;"
                                        >下载</el-link>
                                    </b>
                                    <b v-else>无</b>
                                </template>

                                <template>
                                    <div style="margin-top:10px;">
                                        <span>留言：</span>
                                        <b
                                            style="word-break: break-all;word-wrap: break-word"
                                        >{{ item.content }}</b>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-drawer>

        <!-- 托管酬金  start -->
        <el-dialog
            class="blue-top-border subs-dialog subys-dialog"
            title="托管酬金"
            :visible.sync="payDialog"
            width="400px"
            center
            append-to-body
            @close="dialogClose"
        >
            <div class="notprov-box">
                <!-- <p>您正在进行托管酬金操作。请注意，您确认酬金支付，或确认分期付款行为视为对工程极客当前阶段所提供服务质量的认可。如雇主认为工程极客提供的服务不能满足其需求或合同要求的，应选择验收不通过并拒绝付款。</p> -->
                <p
                    v-if="pay_type == 'Y01'"
                >您正在进行托管酬金操作，当您输入支付密码确认托管后，系统将从您的可用余额里进行扣除，将此酬金托管到系统里，后期将用于支付极客酬金使用。如果可用余额不足，请到我的钱包里充值后再进行托管。</p>
                <p
                    v-if="pay_type == 'W01' || pay_type == 'A01'"
                >您正在进行托管酬金操作，当您扫码支付后，系统将此酬金托管到系统里，后期将用于支付极客酬金使用。</p>
                <ul class="hosting-list hostings-list" style="margin:0;padding:0;">
                    <template v-if="pay_type == 'Y01'">
                        <li class="hosting-item apl-item">
                            <span style="margin:0;">托管金额</span>
                            <i
                                class="warn"
                            >{{ task.trade.remuneration }} 元（余额：{{userInfo.info.surplus}}元）</i>
                        </li>

                        <li class="hosting-item">
                            <span style="margin:0;">支付密码</span>
                        </li>

                        <li class="hosting-item">
                            <el-input
                                v-model="pay_password"
                                type="password"
                                placeholder="请输入支付密码完成托管"
                                autocomplete="new-password"
                            ></el-input>
                        </li>
                    </template>

                    <template v-if="pay_type == 'W01'">
                        <li
                            style="margin-top:30px;text-align:center;font-weight:normal;font-size:16px;"
                        >微信扫码支付</li>
                        <li
                            style="font-size:12px;text-align:center;line-height:30px;color:#74A5F7"
                        >支付金额：{{ pay_price }} 元</li>
                        <li class="hosting-item" style="text-align:center;">
                            <img
                                style="width:160px;border:2px dashed #ddd;padding:10px;border-radius:8px;"
                                :src="pay_img"
                            />
                        </li>
                        <li style="font-size:12px;text-align:center;color:#999;">请用微信扫描二维码进行支付</li>
                        <li style="height:30px;">&nbsp;</li>
                    </template>

                    <template v-if="pay_type == 'A01'">
                        <li
                            style="margin-top:30px;text-align:center;font-weight:normal;font-size:16px;"
                        >支付宝扫码支付</li>
                        <li
                            style="font-size:12px;text-align:center;line-height:30px;color:#74A5F7"
                        >支付金额：{{ pay_price }} 元</li>
                        <li class="hosting-item" style="text-align:center;">
                            <img
                                style="width:160px;border:2px dashed #ddd;padding:10px;border-radius:8px;"
                                :src="pay_img"
                            />
                        </li>
                        <li style="font-size:12px;text-align:center;color:#999;">请用支付宝扫描二维码进行支付</li>
                        <li style="height:30px;">&nbsp;</li>
                    </template>
                </ul>
            </div>
            <span slot="footer" class="dialog-footer" v-if="pay_type == 'Y01'">
                <el-button type="primary" @click="payRemuneration">确认托管</el-button>
                <el-button type="info" @click="payDialog = false">拒绝托管</el-button>
            </span>
        </el-dialog>
        <!-- 托管酬金  end -->

        <!-- 支付酬金  start -->
        <el-dialog
            class="blue-top-border subs-dialog subys-dialog"
            title="支付酬金"
            :visible.sync="payGeekDialog"
            width="400px"
            center
            append-to-body
            @close="dialogClose"
        >
            <div class="notprov-box">
                <p>您正在进行酬金支付的确认操作。请注意，您确认酬金支付，或确认分期付款行为视为对工程极客当前阶段所提供服务质量的认可。如雇主认为工程极客提供的服务不能满足其需求或合同要求的，应选择验收不通过并拒绝付款。</p>
                <ul class="hosting-list hostings-list" style="margin:0;padding:0;">
                    <li class="hosting-item apl-item" style="margin-top:10px;">
                        <span style="margin:0;">支付金额</span>
                        <i class="warn">{{ pay_block_price }} 元</i>
                    </li>

                    <li class="hosting-item">
                        <span style="margin:0;">支付密码</span>
                    </li>

                    <li class="hosting-item">
                        <el-input
                            v-model="pay_password"
                            type="password"
                            placeholder="请输入支付密码完成酬金支付"
                        ></el-input>
                    </li>
                </ul>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="payRemSure">确认支付</el-button>
                <el-button type="info" @click="payGeekDialog = false">返回</el-button>
            </span>
        </el-dialog>
        <!-- 支付酬金  end -->

        <!--某个极客的邀请信息-->
        <el-drawer
            custom-class="list-draw"
            :before-close="closeDraw"
            :visible.sync="oneInviteDraw"
            type="primary"
            title="邀请信息"
            size="550px"
        >
            <div class="invited_detail" style="padding:0 30px;" v-if="inviteData">
                <el-form>
                    <el-form-item
                        label="成果交付时间"
                        label-width="40%"
                        v-if="inviteData && inviteData.delivery_time"
                    >{{ inviteData.delivery_time }}</el-form-item>

                    <el-form-item
                        label="项目酬金"
                        label-width="40%"
                        v-if="task.task_type != 3"
                    >{{ inviteData.remuneration }} 元</el-form-item>

                    <el-form-item
                        label="项目酬金"
                        label-width="40%"
                        v-if="task.task_type == 3"
                    >{{ inviteData.remuneration }} 元</el-form-item>

                    <el-form-item
                        label="总工期"
                        label-width="40%"
                        v-if="task.task_type == 3"
                    >{{ inviteData.work_time }} 天</el-form-item>

                    <el-form-item label="平均每日酬金" label-width="40%" v-if="task.task_type == 3">
                        {{(inviteData.remuneration / inviteData.work_time).toFixed(2)}}
                        元/天
                    </el-form-item>

                    <!-- <el-form-item label="电子合同"
                                  label-width="40%">
                        <el-switch :value="inviteData.is_contract == 1 ? true : false"
                                   disabled
                                   :active-text="inviteData.is_contract == 1 ? '签署' : '不签署'"></el-switch>
                    </el-form-item>-->

                    <el-form-item
                        label="雇主是否托管酬金"
                        label-width="40%"
                        v-if="inviteData.is_remuneration == 1"
                    >
                        <el-switch
                            :value="inviteData.is_remuneration == 1 ? true : false"
                            disabled
                            :active-text="inviteData.is_remuneration == 1 ? '同意托管' : '不托管（线下交易）'"
                        ></el-switch>
                    </el-form-item>

                    <el-form-item label="极客履约保证金" label-width="40%" v-if="inviteData.is_bond == 1">
                        <el-switch
                            :value="inviteData.is_bond == 1 ? true : false"
                            disabled
                            :active-text="inviteData.is_bond == 1 ? '需要托管' : '不需要托管'"
                        ></el-switch>
                    </el-form-item>

                    <el-form-item
                        label="履约保证金金额"
                        label-width="40%"
                        v-if="inviteData.is_bond == 1"
                    >{{ inviteData.bond }} 元</el-form-item>

                    <el-form-item
                        label="雇主支付酬金方式"
                        label-width="40%"
                        v-if="inviteData.is_block == 1"
                    >
                        <el-switch
                            :value="inviteData.is_block == 1 ? true : false"
                            disabled
                            :active-text="inviteData.is_block == 1 ? '分段支付' : '不分段，一次性全额支付'"
                        ></el-switch>
                    </el-form-item>

                    <el-table
                        :data="inviteData.block_employer"
                        style="width: 100%"
                        v-if="inviteData.is_block == 1 && inviteData.block_employer && inviteData.block_employer.length > 1"
                    >
                        <el-table-column prop="title" label="分段名称"></el-table-column>
                        <el-table-column prop="remuneration" label="分段酬金"></el-table-column>
                        <el-table-column prop="done_time" label="预估分段完成时间"></el-table-column>
                    </el-table>
                </el-form>
            </div>
        </el-drawer>

        <!--支付方式选择-->
        <el-dialog
            class="blue-top-border"
            title="支付方式选择"
            :visible.sync="payTypeSelectDialog"
            width="500px"
            center
        >
            <div class="pointCoupon-buy">
                <div class="other-buy" style="border:none;">
                    <ol class="buy-way">
                        <li @click="remuneration('Y01')">
                            <span class="buy-icon">
                                <i class="jk-icon-yuezhifu"></i>
                            </span>
                            <strong>余额支付</strong>
                        </li>
                        <li @click="remuneration('W01')">
                            <span class="buy-icon">
                                <i class="jk-icon-weixinzhifu"></i>
                            </span>
                            <strong>微信支付</strong>
                        </li>
                        <li @click="remuneration('A01')">
                            <span class="buy-icon">
                                <i class="jk-icon-zhifubaozhifu"></i>
                            </span>
                            <strong>支付宝支付</strong>
                        </li>
                    </ol>
                </div>
            </div>
        </el-dialog>
        <!--支付方式选择 end-->

        <!-- ===============================结束项目弹出框=================================  -->
        <el-dialog
            class="red-top-border subs-dialog"
            title="结束项目"
            :visible.sync="overTaskDialog"
            width="640px"
            center
        >
            <div class="renson-box">
                <el-form ref="form">
                    <el-form-item>
                        <p class="end-txt">
                            结束项目后，双方已托管的酬金和履约保证金将在
                            <span class="warn">1个工作日内</span>按原有支付通道返还，请您说明结束项目的原因:
                        </p>
                    </el-form-item>
                    <el-form-item>
                        <el-input type="textarea" class="txtare" v-model="overTaskContent"></el-input>
                    </el-form-item>
                    <p class="end-txt warn">项目一旦结束将无法恢复，是否确定结束项目。</p>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer news-back-wrap">
                <el-button @click="overTask" style="color:#555;" v-if="overTaskStatus">结束</el-button>
                <el-button style="color:#555;" disabled v-else>结束</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Counter from "@/components/common/Counter.vue";
export default {
    name: "todoEmployer",
    props: ["userInfo", "task"],
    components: {
        Counter
    },
    data() {
        return {
            isShowDraw: false, //报名列表抽屉
            oneInviteDraw: false, //某个极客的邀请信息抽屉
            overTaskDialog: false, //结束报名对话框
            overTaskContent: "", //结束报名内容
            pay_password: "", //支付密码
            payDialog: false, //雇主托管酬金对话框
            payGeekDialog: false, //雇主支付酬金对话框
            inviteData: "", //邀请信息
            pay_type: "", //支付方式
            pay_img: "", //支付二维码
            pay_price: "", //支付价格
            payTypeSelectDialog: false, //支付方式选择对话框
            timer: 0, //查询支付是否成功定时器
            loading: false,
            pay_block_price: 0, //需要支付的阶段价格
            selected_block_id: "", //支付酬金选中的分段id
            task_progress_box: false, //项目进度
            overTaskStatus: true, // 结束按钮禁止重复点击
        };
    },
    filters: {
        doneTimeFilter: function (val) {
            if (val && val.done_time) {
                return "项目阶段预计完成时间：" + val.done_time;
            } else {
                return "项目阶段预计完成时间：";
            }
        }
    },
    methods: {
        //报名倒计时截止
        enrollEndEvent: function (tk) {
            this.$emit("enrollEndEvent", tk);
        },

        //其他状态截止
        endCount: function () { },

        //查看报名列表
        showEnroll: function () {
            this.$emit("showEnroll", true);
        },

        //查看项目详情
        taskDetail: function (tk) {
            this.$emit("taskDetail", tk);
        },

        //查看极客详情
        employerDetail(uk, isShowDraw = "") {
            this.$emit("employerDetail", uk);
        },

        //查看极客详情
        geekDetail(uk, isShowDraw = "") {
            this.$emit("geekDetail", uk);
        },

        //查看调解详情
        appealDetail(tk, type = 0) {
            if (type == 1) {
                this.$message.error("提交调解资料时间已过，请耐心等待询龙网专家的调解意见");
            } else {
                this.$router.push("/user/task/appeal_detail?tk=" + tk);
            }
        },

        //发送消息
        sendMessage(uk, isShowDraw = "") {
            if (isShowDraw) {
                this.$emit("sendMessage", { uk: uk, param: "isShowDraw" });
            } else {
                this.$emit("sendMessage", { uk: uk });
            }
        },

        //邀请详情
        showInvite(trade_sn) {
            this.$emit("showInvite", trade_sn);
        },

        //关闭抽屉
        closeDraw() {
            this.isShowDraw = false; // 关闭抽屉
            this.oneInviteDraw = false;
            sessionStorage.removeItem("isShowDraw"); //移除抽屉状态缓存
        },

        //查看某个极客的邀请信息
        showOneInvite(uk, tk) {
            let _this = this;
            _this.post("/task/task/invite_detail", { uk: uk, tk: tk }, function (
                res
            ) {
                if (res.code == 200) {
                    _this.inviteData = res.data;
                    _this.oneInviteDraw = true;
                } else {
                    _this.$message.error("获取失败");
                }
            });
        },

        //邀请用户
        invite_user(tk, uk, param = "") {
            if (
                this.userInfo.info.attestation != 2 ||
                this.userInfo.attestation == ""
            ) {
                this.$message.error("认证后才可以发出邀请");
                return false;
            }

            //侧边栏是否保持展开
            if (param == "isShowDraw") {
                sessionStorage.setItem("isShowDraw", this.isShowDraw);
            }

            this.$router.push("/user/task/invite?tk=" + tk + "&uk=" + uk);
        },

        //填写合同签署信息
        contractUpdate(tk, uk) {
            this.$router.push(
                "/user/task/update_contract?tk=" + tk + "&uk=" + uk
            );
        },

        //项目结束事件
        overTask() {
            let _this = this;
            _this.overTaskStatus = false;
            if (!_this.overTaskContent) {
                _this.$message.error(
                    "项目结束说明不能为空，请填写结束项目的详细说明。"
                );
                return false;
            }
            _this.post(
                "/task/task/over_task",
                {
                    tk: _this.$route.query.tk,
                    over_content: _this.overTaskContent
                },
                res => {
                    if (res.code == 200) {
                        _this.$message.success("结束项目操作成功");
                        _this.overTaskContent = ""; //情况结束项目内容
                        _this.overTaskDialog = false; //关闭结束项目对话框
                        _this.loadDetailData(); //刷新数据
                    } else {
                        if (res.msg) {
                            _this.$message.error(res.msg);
                        } else {
                            _this.$message.error("结束项目失败，未知错误");
                        }
                    }
                    _this.overTaskStatus = true;
                }
            );
        },

        //查看电子合同预览
        showContract(tk, uk) {
            this.$emit("showContract", { tk: tk, uk: uk });
        },

        //查看已签署的电子合同预览
        showSignContract(tk, uk) {
            this.$emit("showSignContract", { tk: tk, uk: uk });
        },

        //查看阶段成果
        viewResult(tk, id) {
            this.$router.push("/user/task/result?tk=" + tk + "&id=" + id);
        },

        //合同签署
        contractSign(tk) {
            this.$emit("contractSign", { uk: tk });
        },

        //托管酬金
        remuneration(type = "") {
            this.pay_type = type;
            this.payTypeSelectDialog = false;
            if (type == "Y01") {
                if (this.userInfo.info.has_pay_password != 1) {
                    this.$message.error(
                        "您还未设置支付密码，请到账户安全设置支付密码后再进行托管酬金操作!"
                    );

                    //缓存当前页面链接，方面后面设置成功后跳转
                    sessionStorage.setItem(
                        "currentPageUrl",
                        document.location.pathname + document.location.search
                    );

                    //跳转到设置支付密码界面
                    this.$router.push(
                        "/user/validpassword?type=1&routerName=userSettingPassword&title=设置支付密码"
                    );
                    return false;
                }

                if (
                    parseFloat(this.userInfo.info.surplus) <
                    parseFloat(this.task.trade.bond)
                ) {
                    this.$message.error(
                        "您的可用余额不足，请到我的钱包中心充值后再来托管履约保证金！"
                    );
                    return false;
                }
                this.payDialog = true; //打开输入密码支付对话框
            } else {
                this.payRemuneration(); //如果是微信或者支付宝支付则创建订单
            }
        },

        //创建托管酬金订单
        payRemuneration() {
            let _this = this;
            if (_this.pay_type == "Y01") {
                if (!_this.pay_password) {
                    _this.$message.error("请输入支付密码");
                    return false;
                }
            }
            _this.loading = true;
            _this.post(
                "/task/task/pay_remuneration",
                {
                    tk: _this.$route.query.tk,
                    password: _this.pay_password,
                    pay_type: _this.pay_type
                },
                function (res) {
                    _this.loading = false;
                    if (200 == res.code) {
                        _this.pay(res.data);
                    } else {
                        if (res.msg) {
                            _this.$message.error(res.msg);
                        } else {
                            _this.$message.error("托管失败，请重试");
                        }
                    }
                }
            );
        },

        //确认托管酬金事件
        pay(order_no) {
            let _this = this;
            _this.pay_img = "";
            _this.pay_price = "";
            _this.loading = true;
            _this.payDialog = false;
            _this.post(
                "/pay/pay/pay",
                { order_no: order_no, password: _this.pay_password },
                function (res) {
                    _this.loading = false;
                    if (200 == res.code) {
                        if (_this.pay_type == "Y01") {
                            _this.$message.success("托管成功");
                            _this.payDialog = false;
                            _this.$emit("reload"); //刷新数据
                        } else {
                            _this.pay_img = _this.websiteConfigs.sourceUrl + res.data.img;
                            _this.pay_price = res.data.price;
                            _this.payDialog = true; //打开二维码扫码对话框
                            _this.payTypeSelectDialog = false;
                            _this.timer = setInterval(() => {
                                _this.isPayCheck();
                            }, 2000);
                        }
                    } else {
                        if (res.msg) {
                            _this.$message.error(res.msg);
                        } else {
                            _this.$message.error("托管失败，请重试");
                        }
                    }
                }
            );
        },

        //对话框关闭事件
        dialogClose() {
            clearInterval(this.timer);
            this.payDialog = false;
            this.loading = false;
        },

        //支付酬金
        showPayRemDialog(val, id) {
            this.payGeekDialog = true;
            this.pay_block_price = val;
            this.selected_block_id = id;
        },

        //支付酬金给极客
        payRemSure() {
            let _this = this;
            _this.loading = true;
            _this.payGeekDialog = false;
            _this.post(
                "/task/task/pay_rem_sure",
                {
                    tk: _this.$route.query.tk,
                    block_id: _this.selected_block_id,
                    password: _this.pay_password
                }, res => {
                    _this.loading = false;
                    if (res.code == 200) {
                        _this.$message.success("支付成功");
                        _this.payGeekDialog = false;
                        _this.$emit("reload");
                    } else {
                        if (res.msg) {
                            _this.$message.success(res.msg);
                        } else {
                            _this.$message.success("支付失败");
                        }
                    }
                }
            );
        },

        //是否支付成功
        isPayCheck() {
            let _this = this;
            this.post(
                "/pay/pay/is_pay",
                {
                    order_no: _this.order_no
                },
                function (res) {
                    if (res.code == 200) {
                        _this.$message.success("恭喜你，支付成功！");
                        clearInterval(_this.timer);
                        _this.payDialog = false;
                        _this.$emit("reload");
                    }
                }
            );
        },

        //查看项目进度
        task_progress_view() {
            let task_progress_box = this.task_progress_box;
            if (task_progress_box == true) {
                this.task_progress_box = false; //查看项目进度
                sessionStorage.setItem("task_progress_status", 2);
            } else {
                this.task_progress_box = true; //查看项目进度
                sessionStorage.setItem("task_progress_status", 1);
            }
        },

        //项目评价
        task_appraise(tk) {
            this.$router.push("/user/task/appraise?tk=" + tk);
        },

        //查看项目评价
        view_appraise(tk) {
            this.$router.push("/user/appraise");
        },

        //项目资料
        viewFile(tk) {
            this.$router.push("/user/task/file?tk=" + tk);
        },

        //申请调解
        appeal(tk) {
            if (this.task.trade.is_remuneration != 1) {
                this.$message.error(
                    "该项目未托管酬金，没有该权限。"
                );
                return false;
            }
            if (
                (this.task.status == 7 || this.task.status == 9) &&
                this.task.trade &&
                (this.task.trade.status == 3 || this.task.trade.status == 5)
            ) {
                this.$router.push("/user/task/appeal?tk=" + tk);
            } else {
                this.$message.error(
                    "该项目状态不是工作中，不可提交调解申请！"
                );
            }
        },

        //打开准备签署合同抽屉
        contractStep() {
            if (this.task.trade.is_remuneration != 1) {
                this.$message.error(
                    "该项目未托管酬金，没有该权限。"
                );
                return false;
            }
            this.$emit("contractStep");
        }
    },
    mounted() {
        let task_progress_status = sessionStorage.getItem(
            "task_progress_status"
        );

        if (task_progress_status == 1) {
            this.task_progress_box = true;
        } else {
            this.task_progress_box = false;
        }
    }
};
</script>

<style lang="less" scoped>
@import "../../../styles/todos2.less";
@import "../../../styles/todo.less";
.invited_detail {
    .el-input.is-disabled .el-input__inner {
        border: none;
        width: auto;
    }
}

.contract_box {
    .td-item-inner {
        height: 150px;
        margin-bottom: 10px;
    }
    .left {
        width: 530px;
        height: 100%;
        float: left;
        border-right: 1px solid #eee;
    }
    .right {
        width: 330px;
        height: 100%;
        float: right;
        position: relative;
        .title {
            font-size: 16px;
            height: 30px;
            margin-left: 10px;
            position: absolute;
        }
        .des-it {
            width: 100%;
            clear: both;
            margin-left: 10px;
            position: absolute;
            z-index: 1;
            height: 40px;
        }
        .time {
            height: 50px;
            position: absolute;
            top: 40px;
        }
        .desc {
            position: absolute;
            top: 80px;
        }
        .icon {
            position: absolute;
            z-index: 1;
            right: 104px;
            top: 3px;
            width: 100px;
        }
    }
}

.tgqk {
    padding-left: 20px;
    float: left;
    font-size: 12px;
    div {
        line-height: 30px;
    }
    .value {
        color: red;
    }
    .employer {
        margin-left: 70px;
    }
    .geek {
        margin-left: 50px;
    }
}

/deep/.el-step__title {
    font-size: 14px;
}

/deep/ .enroll-limit .el-drawer__body {
    max-height: 96%;
}

.el-step__title.is-finish {
    color: #587ddc;
}

/deep/ .el-step__title.is-process {
    color: #409eff !important;
    border-color: #409eff !important;
}

/deep/.el-step__head.is-process {
    color: #409eff !important;
    border-color: #409eff !important;
}

/deep/.el-step__head.is-finish {
    .el-step__line {
        background: #409eff !important;
    }
}

/deep/.el-step__main {
    .el-step__description {
        color: #409eff !important;
    }
}
</style>